.no-border {
    border: none !important;
}

.card-header {
    border: none !important;    
    background-color: white;
}

.section-header {
    padding-left: 0px;
    color: #5469d4;
}